function parseFileSizeString(fileSizeString: string): number {
    const trimmedInput = fileSizeString.trim();

    // Regular expression to match the numeric part and the unit part
    const regex = /^(\d+)(B|KB|MB|GB|TB)$/i;

    const match = regex.exec(trimmedInput);

    if (!match) {
        throw new Error('Invalid file size format');
    }

    const value = parseInt(match[1], 10);
    const unit = match[2].toUpperCase();

    switch (unit) {
        case 'B':
            return value;
        case 'KB':
            return value * 1024;
        case 'MB':
            return value * 1024 * 1024;
        case 'GB':
            return value * 1024 * 1024 * 1024;
        case 'TB':
            return value * 1024 * 1024 * 1024 * 1024;
        default:
            throw new Error('Unsupported unit "' + unit + '"');
    }
}

export default parseFileSizeString;
