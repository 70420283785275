import { processContent } from 'fieldFactory/display/components/DownloadFromLink';
import b64ToFile from 'fieldFactory/util/b64ToFile';
import React, { useCallback, useEffect, useState } from 'react';
import buildHeaders from 'sideEffect/buildHeaders';

const config = require('../../config.js');

const getContent = (url) =>
    fetch(`${config.BACKEND_BASE_URL}${url}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
        }),
    });

const ProcessDocPdf: React.FC<{
    url: string;
    fileName: string;
    contentType: string;
}> = ({ url, fileName, contentType }) => {
    const [dataUrl, setDataUrl] = useState('');
    const [error, setError] = useState(null);
    const request = useCallback(() => {
        setError(null);
        if (!url) {
            return;
        }
        getContent(url)
            .then(processContent(contentType))
            .then((b64) => b64ToFile(b64, fileName, contentType))
            .then((file) => {
                setDataUrl(URL.createObjectURL(file.tag === 'file' ? file.file : file.blob));
            })
            .catch((e) => {
                console.error(e);
                setError(e);
            });
    }, [url, contentType, setDataUrl, fileName]);

    useEffect(() => {
        request();
    }, [request]);

    if (error) {
        return (
            <div>
                An error occurred (see console).&nbsp;<button onClick={request}>Retry?</button>
            </div>
        );
    }
    if (!dataUrl) {
        return null;
    }

    return (
        <object type="application/pdf" data={dataUrl} width="100%" height="100%">
            {fileName}
        </object>
    );
};

export default ProcessDocPdf;
