import React from 'react';
import compose from 'recompose/compose';
import { change, formValueSelector, WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';
import { connect } from 'react-redux';
import Clear from '@material-ui/icons/Clear';
import { FormLabel, IconButton, WithTheme } from '@material-ui/core';
import b64ToFile, { saveFile } from '../../../util/b64ToFile';
import withFormContext, { InjectedProps as ReduxFormContextProps } from '../hoc/withFormContext';
import { FormControl, withTheme } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import { RootState } from 'reducers/rootReducer';
import { Dispatch } from 'redux';
import DmsDoc, { DmsImageDoc } from '../DmsDoc';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { getRefEntityName } from 'components/generics/utils/viewConfigUtils';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { TextFieldUtils } from 'fieldFactory/input/hooks/useTextFieldUtils';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import parseFileSizeString from './util/parseFileSizeString';

interface DMSProps {
    mode: 'DMS';
    resource: string;
}
interface FILEProps {
    mode: 'FILE';
}

type FileInputProps = {
    mode: 'DMS' | 'FILE';
    isImage?: boolean;
    imageWidth?: string;
    source: string;
    disabled?: boolean;
    meta: WrappedFieldMetaProps;
    input: WrappedFieldInputProps & {
        onBlur: (value: string | null) => void;
        value: string | null | undefined;
    };
    renderLabel: boolean;
    ariaInputProps: {};
    id?: string;
    label: string | JSX.Element;
    deriveOnUpload: {
        [field: string]: (uploadedB64: string) => any;
    };
} & (DMSProps | FILEProps);

const popOffProperty = (source: string) => source.slice(0, source.lastIndexOf('.'));

const makeMapStateToProps = () => {
    const selectors: { [formId: string]: Function } = {};
    const getEntities = createGetEntities();
    return (state: RootState, props: FileInputProps) => {
        if (!selectors[props.meta.form]) {
            selectors[props.meta.form] = formValueSelector(props.meta.form);
        }
        const fileName: string = selectors[props.meta.form](state, `${props.source}FileName`);
        const contentType: string = selectors[props.meta.form](state, `${props.source}ContentType`);
        if (props.mode === 'DMS') {
            const dmsEntityId: string = selectors[props.meta.form](state, 'id');
            const dmsResource =
                props.source.indexOf('.') === -1
                    ? props.resource
                    : getRefEntityName(state.viewConfig, props.resource, popOffProperty(props.source), 'TRAVERSE_PATH');
            const documentIdentifier: string | undefined = traverseGetData(
                state.viewConfig,
                `${props.source}Identifier`,
                {
                    id: dmsEntityId,
                    entityType: dmsResource,
                },
                getEntities(state),
            ).getOrElse(undefined);
            return {
                mode: 'DMS',
                resource: props.resource,
                fileName,
                maxFileSize: state.basicInfo && state.basicInfo.maxFileSize,
                contentType,
                dmsEntityId,
                documentIdentifier,
            } as const;
        }
        return { mode: 'FILE', fileName, contentType } as const;
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: FileInputProps) => ({
    changeFileType: (contentType: string | null) =>
        dispatch(change(props.meta.form, `${props.source}ContentType`, contentType)),
    changeFileName: (fileName: string | null) => dispatch(change(props.meta.form, `${props.source}FileName`, fileName)),
    // for calculating derived fields
    change: (field: string, value) => dispatch(change(props.meta.form, field, value)),
});

type FileInputComponentProps = WithTheme &
    FileInputProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<ReturnType<typeof makeMapStateToProps>> &
    ReduxFormContextProps;

interface FileInputState {
    readonly file: { tag: 'file'; file: File } | { tag: 'blob'; blob: Blob; filename: string } | 'initial_empty' | null;
}

class FileInputComponent extends React.Component<FileInputComponentProps, FileInputState> {
    private errorMessageId = uniqueId('file-errormessageid-');
    private fallbackInputId = uniqueId('fileinput-');
    static defaultProps = {
        deriveOnUpload: {},
        ariaInputProps: {},
        renderLabel: true,
    };
    constructor(props: FileInputComponentProps) {
        super(props);
        this.state = {
            file: 'initial_empty',
        };
    }
    componentDidMount() {
        this.setState({
            file: this.props.input.value
                ? b64ToFile(this.props.input.value, this.props.fileName, this.props.contentType)
                : 'initial_empty',
        });
        this.props._reduxForm.registerField(`${this.props.source}FileName`, 'Field');
        this.props._reduxForm.registerField(`${this.props.source}ContentType`, 'Field');

        Object.keys(this.props.deriveOnUpload).forEach((field) => {
            this.props._reduxForm.registerField(field, 'Field');
        });
    }

    componentWillReceiveProps(nextProps: FileInputComponentProps) {
        if (
            nextProps.input.value !== this.props.input.value ||
            nextProps.fileName !== this.props.fileName ||
            nextProps.contentType !== this.props.contentType
        ) {
            const file =
                nextProps.input.value && b64ToFile(nextProps.input.value, nextProps.fileName, nextProps.contentType);
            if (file) {
                this.setState({
                    file: file,
                });
            } else if (this.state.file !== 'initial_empty') {
                // if we are initial-empty, only start changing the file displayed when the file is changed (cleared)
                this.setState({
                    file: null,
                });
            }
        }
    }

    saveFile = (file: File | null) => {
        // Saving files to state for further use and closing Modal.
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                const mime = (reader!.result as string).substring(
                    'data:'.length,
                    (reader!.result as string).indexOf(';base64'),
                );
                const b64 = (reader!.result as string).split('base64,')[1];
                this.props.input.onBlur(b64);
                this.props.changeFileType(mime);
                // doesn't depend on result but it looks nicer when all these things load simultaneously
                this.props.changeFileName(file && file.name);

                // change derived fields
                Object.entries(this.props.deriveOnUpload).forEach(([key, onUpload]) => {
                    this.props.change(key, onUpload(b64));
                });
            },
            false,
        );
        if (file) {
            reader.readAsDataURL(file);
        } else {
            this.props.input.onBlur(null);
            this.props.changeFileName(null);
            this.props.changeFileType(null);
        }
        this.setState({ file: file && { tag: 'file', file } });
    };
    renderDocumentLink = () => {
        const { disabled, isImage } = this.props;
        const { file: _file } = this.state;
        const file = _file === 'initial_empty' ? null : _file;
        return (
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                }}
            >
                {isImage ? (
                    <img
                        style={{ width: 'calc(100% - 24px)', height: 'auto' }}
                        src={'data:image/png;base64,' + this.props.input.value}
                        alt=""
                    />
                ) : (
                    <a // eslint-disable-line
                        href="javascript:;" // eslint-disable-line
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (file) {
                                saveFile(file);
                            }
                        }}
                    >
                        {file && (file.tag === 'file' ? file.file.name : file.filename)}
                    </a>
                )}
                {!disabled && (
                    <IconButton
                        onClick={(e) => {
                            this.saveFile(null);
                        }}
                        aria-label="Clear file"
                        style={{ padding: 0, margin: 0, height: 'unset', width: 'unset' }}
                    >
                        <Clear />
                    </IconButton>
                )}
            </span>
        );
    };
    fileIsInitialEmpty = () => {
        const { file } = this.state;
        return file === 'initial_empty';
    };
    fileIsEmpty = () => {
        const { file } = this.state;
        return !file || this.fileIsInitialEmpty();
    };
    renderUpload = () => {
        const {
            ariaInputProps,
            disabled,
            id,
            maxFileSize,
            meta: { touched, error },
        } = this.props;
        const fileInputId = id || this.fallbackInputId;
        return (
            <EvaluateFormattedMessage>
                {({ translate }) => (
                    <input
                        type="file"
                        {...ariaInputProps}
                        aria-describedby={touched && error ? this.errorMessageId : undefined}
                        aria-invalid={!!(touched && error)}
                        disabled={disabled}
                        id={fileInputId}
                        onChange={(e) => {
                            if (e.target.files) {
                                const file = e.target.files[0];
                                if (file.size === 0) {
                                    const message = translate({
                                        id: 'file.failure.empty',
                                        defaultMessage: 'File could not be uploaded because the file is empty.',
                                    });
                                    alert(message);
                                    e.target.value = '';
                                    return;
                                }
                                if (maxFileSize && file.size > parseFileSizeString(maxFileSize)) {
                                    const message = translate(
                                        {
                                            id: 'file.failure.size',
                                            defaultMessage: 'File is too large. Upload limit is {0}',
                                        },
                                        { '0': maxFileSize },
                                    );
                                    alert(message);
                                    e.target.value = '';
                                    return;
                                }
                                this.saveFile(file);
                            }
                        }}
                    />
                )}
            </EvaluateFormattedMessage>
        );
    };
    renderIdLink = () => {
        if (this.props.mode === 'DMS') {
            const { resource, source, dmsEntityId, disabled, isImage, imageWidth, label } = this.props;
            return (
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: imageWidth || '100%',
                    }}
                >
                    {isImage ? (
                        <div style={{ width: 'calc(100% - 24px)' }}>
                            <DmsImageDoc
                                type="Input(Entity)"
                                source={source}
                                resource={resource}
                                altText={typeof label === 'string' ? label : ''}
                                input={{
                                    value: dmsEntityId,
                                }}
                            />
                        </div>
                    ) : (
                        <DmsDoc
                            type="Input(Entity)"
                            // 'source' and 'resource' are used internally to lookup the relevant restUrl
                            source={source}
                            resource={resource}
                            input={{
                                value: dmsEntityId,
                            }}
                        />
                    )}
                    {!disabled && (
                        <IconButton
                            onClick={(e) => {
                                this.saveFile(null);
                            }}
                            aria-label="Clear file"
                            style={{ padding: 0, margin: 0, height: 'unset', width: 'unset' }}
                        >
                            <Clear />
                        </IconButton>
                    )}
                </span>
            );
        }
    };
    renderContent = () => {
        if (this.props.mode === 'FILE') {
            return !this.fileIsEmpty() ? this.renderDocumentLink() : this.renderUpload();
        }
        if (this.props.documentIdentifier && !this.fileIsEmpty()) {
            return this.renderDocumentLink();
        }
        if (this.props.documentIdentifier && this.fileIsInitialEmpty()) {
            return <div style={{ marginTop: '3px' }}>{this.renderIdLink()}</div>;
        }
        if (!this.props.documentIdentifier && !this.fileIsEmpty()) {
            return this.renderDocumentLink();
        }
        return this.renderUpload();
    };
    render() {
        // If we already saved files they will be shown again in modal preview.
        const { meta, theme, renderLabel, id, disabled } = this.props;
        const fileInputId = id || this.fallbackInputId;
        const trueError = meta.touched && meta.error;
        return (
            <TextFieldUtils meta={meta}>
                {({ muiErrorProp, helperText, textErrorClass }) => (
                    <FormControl disabled={disabled} margin="none" style={{ width: '100%' }}>
                        {renderLabel && <FormLabel htmlFor={fileInputId}>{this.props.label}</FormLabel>}
                        <div className={textErrorClass} style={trueError ? { color: theme.palette.error.dark } : {}}>
                            <div style={{ marginTop: '.75em', paddingTop: 5, paddingBottom: 5 }}>
                                {this.renderContent()}
                            </div>
                            {muiErrorProp && (
                                <span
                                    className={textErrorClass}
                                    id={this.errorMessageId}
                                    style={{
                                        fontSize: 'small',
                                        color: trueError ? theme.palette.error.dark : undefined,
                                    }}
                                >
                                    {helperText}
                                </span>
                            )}
                        </div>
                    </FormControl>
                )}
            </TextFieldUtils>
        );
    }
}

const FileInput: React.ComponentType<FileInputProps> = compose(
    connect(makeMapStateToProps, mapDispatchToProps),
    withTheme,
    withFormContext,
)(FileInputComponent);

export default FileInput;
