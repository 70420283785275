import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';
import { TextFieldProps } from '@material-ui/core';

interface EnvConfig {
    css?: string;
    useEmailAsAccountDisplay?: boolean;
    hideTaskDrawer?: boolean | string; // this can be a SPEL expression, e.g.
    // "hideTaskDrawer":"getCurrentUserLogin() == 'mishatest' || getCurrentUserLogin() == 'automation'",
    loginText?: string;
    // e.g.
    // "taskDrawerTaskLinkUrl":"/tasks?$[taskPageWillHaveSubmitButton ? 'delaySearch=true&' :
    taskDrawerTaskLinkUrl?: string;
    hideTaskScheduledDate?: boolean;
    noAnonWelcomeText?: boolean;
    baseModelerUrl?: string;
    footerConfig?: {
        footerHtml: string;
    };
    listExportLimit?: number;
    fetchGlobalAlerts?: boolean;
    togglablePrintMode?: boolean;
    primaryColor?: string;
    offlinePrimaryColor?: string;
    fontFamily?: string;
    toolbarImageUrl?: string;
    useNativeSelect?: boolean;
    secondaryColor?: string;
    offlineSecondaryColor?: string;
    appTitle?: string;
    offlineAppTitleHtml?: string;
    onlinePwaAppTitleHtml?: string;
    offlineWelcomeMessageHtml?: string;
    loginHtml?: string;
    resetPageText?: string;
    hoverMenu?: boolean;
    firstLoginTotpQrExplainerHtml?: string;
    totpCodeEntryExplainerHtml?: string;
    useViewDefs?: boolean;
    loginLogo?: string;
    fieldVariant?: TextFieldProps['variant'];
    showContinueWithoutLoggingIn?: boolean;
    hideDashboardDropdown?: string;
    recaptchaSiteKey?: string;
    customAuthPageHtml?: string;
    hidePaginationIfNotNeeded?: boolean;
    customRegistrationPageHtml?: string;
    customPasswordResetPageHtml?: string;
    gaTrackingId?: string;
    overrideHomepage?: {
        textTemplateName: string;
    };
    overrideAppbarTitleForRoutes?: {
        route: string | string[];
        html: string;
        mobileHtml?: string;
        hideAllMenuItems?: boolean;
        toolbarStyles?: React.CSSProperties;
        mobileToolbarStyles?: React.CSSProperties;
        navLink?: boolean;
    }[];
    storageMode?: 'sessionStorage' | 'fallback';
    mapRequests: {
        match: string;
        to: string;
    }[];
    overrideLoginAppbarTitle?: string;
    allowOffline?: {
        startForms?: false;
        useAppBannerHtml?: string;
        expireDays: number;
        processes?: {
            [key: string]: {
                tasks?: {
                    [key: string]: true | string[]; // <- if array, an array of roles allowed.
                };
            };
        };
    };
    serviceWorker?: {
        cacheOnLogin?: { url: string }[];
        preload?: { url: string; revision: number | string | null };
    };
    languages?: string[]; // e.g. ["en", "es"]
    useOldManyWidgetImpl?: boolean;
    spel?: {
        mode?: 'compatibility' | 'ts-spel' | 'spel2js';
    };
    reports?: {
        useRelativeDates?: boolean;
    };
    disablePopupNavigationArrow?: boolean;
    useFullWidthDateFields?: boolean;
}
type BasicInfo = RootState['basicInfo'];

const getEnvConfig = (basicInfo: BasicInfo) =>
    fromNullable(basicInfo)
        .mapNullable((bi) => bi.application)
        .chain((a) => fromPredicate<EnvConfig>(Boolean)(a.config));

export const getUseRelativeDates = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.reports?.useRelativeDates)
        .getOrElse(false);

export const getUseFullWidthDateFields = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.useFullWidthDateFields)
        .getOrElse(false);

export const getUseDisablePopupNavigationArrow = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.disablePopupNavigationArrow)
        .getOrElse(false);

export const getUseSpelMode = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.spel?.mode)
        .getOrElse('spel2js');

export const getUseEmailAsAccountDisplay = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.useEmailAsAccountDisplay)
        .getOrElse(false);

export const getUseOldManyWidgetImpl = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.useOldManyWidgetImpl)
        .getOrElse(false);

export const getUseAppBannerHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.allowOffline?.useAppBannerHtml)
        .toUndefined();

export const getExpireDays = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.allowOffline?.expireDays)
        .getOrElse(3);

export const getAllowOfflineTasks = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.allowOffline?.processes)
        .getOrElse(null);

export const getOfflineStartForms = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.allowOffline?.startForms)
        .getOrElse(null);

export const getMapRequests = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.mapRequests)
        .getOrElse(null);

export const getStorageMode = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.storageMode)
        .getOrElse('fallback');

const getOverrideHomepage = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.overrideHomepage)
        .toUndefined();

const getGATrackingId = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.gaTrackingId)
        .toUndefined();

const getEnvConfigCss = (basicInfo: BasicInfo): string | null =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string | null>(Boolean)(envConfig.css))
        .getOrElse(null);

const getFetchGlobalAlerts = (basicInfo: BasicInfo): Boolean =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.fetchGlobalAlerts)
        .getOrElse(true);

const getListExportLimit = (basicInfo: BasicInfo): number =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.listExportLimit)
        .getOrElse(2000);

const getEnvConfigFieldVariant = (basicInfo: BasicInfo): TextFieldProps['variant'] | null =>
    getEnvConfig(basicInfo)
        .chain((envConfig) =>
            fromPredicate<TextFieldProps['variant'] | null>(
                (v) => v && (v === 'filled' || v === 'outlined' || v === 'standard'),
            )(envConfig.fieldVariant),
        )
        .toUndefined();

const getLoginConfiguredText = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.loginText))
        .getOrElse(null);

const getLanguages = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.languages)
        .getOrElse(null);

const getHidePaginationIfNotNeeded = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromNullable(envConfig.hidePaginationIfNotNeeded))
        .getOrElse(false);

const togglablePrintMode = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.togglablePrintMode)
        .getOrElse(true);

const getToolbarImageUrl = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.toolbarImageUrl))
        .getOrElse(null);

const getCustomAuthPageHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig?.customAuthPageHtml)
        .toUndefined();

const getCustomRegistrationPageHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig?.customRegistrationPageHtml)
        .toUndefined();

const getCustomPasswordResetPageHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig?.customPasswordResetPageHtml)
        .toUndefined();

const getUseNativeSelect = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig?.useNativeSelect)
        .getOrElse(false);

const getFooterConfig = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<(typeof envConfig)['footerConfig']>(Boolean)(envConfig.footerConfig))
        .getOrElse(null);

const getPrimaryColorConfig = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.primaryColor))
        .getOrElse(null);

const getOfflinePrimaryColorConfig = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.offlinePrimaryColor ?? envConfig.primaryColor))
        .getOrElse(null);

const getFontFamilyConfig = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.fontFamily))
        .toUndefined();

const getSecondaryColorConfig = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.secondaryColor))
        .getOrElse(null);

const getOfflineSecondaryColorConfig = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) =>
            fromPredicate<string>(Boolean)(envConfig.offlineSecondaryColor ?? envConfig.secondaryColor),
        )
        .getOrElse(null);

const getResetPageText = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.resetPageText))
        .getOrElse(null);

const getLoginHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.loginHtml))
        .getOrElse(null);

const getLoginLogo = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.loginLogo))
        .getOrElse(null);

const getHoverMenu = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<boolean>(Boolean)(envConfig.hoverMenu))
        .getOrElse(false);

const getHideTaskScheduledDate = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<boolean>(Boolean)(envConfig.hideTaskScheduledDate))
        .getOrElse(false);

const getHideDashboardDropdown = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.hideDashboardDropdown))
        .getOrElse(null);

const getShowContinueWithoutLoggingIn = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.showContinueWithoutLoggingIn)
        .map(Boolean)
        .getOrElse(true);

const getOverrideAppbarTitleForRoute = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) =>
            fromPredicate<EnvConfig['overrideAppbarTitleForRoutes']>(Boolean)(envConfig.overrideAppbarTitleForRoutes),
        )
        .getOrElse(null);

const getRecaptchaSiteKey = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.recaptchaSiteKey))
        .getOrElse(null);

const getOverrideLoginAppbarTitle = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.overrideLoginAppbarTitle))
        .getOrElse(null);
export const getUseViewDefs = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<boolean>(Boolean)(envConfig.useViewDefs))
        .getOrElse(false);

const getAppTitle = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.appTitle))
        .getOrElse(null);

const getOfflineAppTitleHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.offlineAppTitleHtml))
        .getOrElse(null);

const getOnlinePwaAppTitleHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.onlinePwaAppTitleHtml))
        .getOrElse(null);

const getOfflineWelcomeMessageHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.offlineWelcomeMessageHtml))
        .getOrElse(null);

const getFirstLoginTotpQrExplainerHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.firstLoginTotpQrExplainerHtml))
        .getOrElse(null);

const getTotpCodeEntryExplainerHtml = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.totpCodeEntryExplainerHtml))
        .getOrElse(null);

const getTaskDrawerTaskLinkTemplate = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .chain((envConfig) => fromPredicate<string>(Boolean)(envConfig.taskDrawerTaskLinkUrl))
        .getOrElse(null);

const getHasNoAnonWelcomeMessage = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .map((envConfig) => envConfig.noAnonWelcomeText || false)
        .getOrElse(false);

const getBaseModelerUrl = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.baseModelerUrl)
        .toUndefined();

const getCacheOnLogin = (basicInfo: BasicInfo) =>
    getEnvConfig(basicInfo)
        .mapNullable((envConfig) => envConfig.serviceWorker?.cacheOnLogin)
        .getOrElse([]);

export const getHasNoAnonWelcomeMessageSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getHasNoAnonWelcomeMessage,
);

export const getBaseModelerUrlSelector = createSelector((state: RootState) => state.basicInfo, getBaseModelerUrl);

export const getMapRequestsSelector = createSelector((state: RootState) => state.basicInfo, getMapRequests);

export const getUseSpelModeSelector = createSelector((state: RootState) => state.basicInfo, getUseSpelMode);

export const getUseRelativeDatesSelector = createSelector((state: RootState) => state.basicInfo, getUseRelativeDates);

export const getUseFullWidthDateFieldsSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getUseFullWidthDateFields,
);

export const getUseDisablePopupNavigationArrowSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getUseDisablePopupNavigationArrow,
);

export const getUseEmailAsAccountDisplaySelector = createSelector(
    (state: RootState) => state.basicInfo,
    getUseEmailAsAccountDisplay,
);

export const getUseOldManyWidgetImplSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getUseOldManyWidgetImpl,
);

export const getUseAppBannerHtmlSelector = createSelector((state: RootState) => state.basicInfo, getUseAppBannerHtml);

export const getExpireDaysSelector = createSelector((state: RootState) => state.basicInfo, getExpireDays);

export const getAllowOfflineTasksSelector = createSelector((state: RootState) => state.basicInfo, getAllowOfflineTasks);

export const getOfflineStartFormsSelector = createSelector((state: RootState) => state.basicInfo, getOfflineStartForms);

export const getEnvConfigCssSelector = createSelector((state: RootState) => state.basicInfo, getEnvConfigCss);

export const getOverrideHomepageSelector = createSelector((state: RootState) => state.basicInfo, getOverrideHomepage);

export const getStorageModeSelector = createSelector((state: RootState) => state.basicInfo, getStorageMode);

export const getGATrackingIdSelector = createSelector((state: RootState) => state.basicInfo, getGATrackingId);

export const getFetchGlobalAlertsSelector = createSelector((state: RootState) => state.basicInfo, getFetchGlobalAlerts);

export const getListExportLimitSelector = createSelector((state: RootState) => state.basicInfo, getListExportLimit);

export const getLanguagesSelector = createSelector((state: RootState) => state.basicInfo, getLanguages);

export const getEnvConfigUseViewDefsSelector = createSelector((state: RootState) => state.basicInfo, getUseViewDefs);

export const getEnvConfigFieldVariantSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getEnvConfigFieldVariant,
);

export const getLoginConfiguredTextSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getLoginConfiguredText,
);

export const getHidePaginationIfNotNeededSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getHidePaginationIfNotNeeded,
);

export const getToolbarImageUrlSelector = createSelector((state: RootState) => state.basicInfo, getToolbarImageUrl);

export const togglablePrintModeSelector = createSelector((state: RootState) => state.basicInfo, togglablePrintMode);

export const getUseNativeSelectSelector = createSelector((state: RootState) => state.basicInfo, getUseNativeSelect);

export const getCustomAuthPageHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getCustomAuthPageHtml,
);

export const getCustomRegistrationPageHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getCustomRegistrationPageHtml,
);

export const getCustomPasswordResetPageHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getCustomPasswordResetPageHtml,
);

export const getFooterConfigSelector = createSelector((state: RootState) => state.basicInfo, getFooterConfig);

export const getPrimaryColorSelector = createSelector((state: RootState) => state.basicInfo, getPrimaryColorConfig);

export const getOfflinePrimaryColorSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getOfflinePrimaryColorConfig,
);

export const getFontFamilySelector = createSelector((state: RootState) => state.basicInfo, getFontFamilyConfig);

export const getSecondaryColorSelector = createSelector((state: RootState) => state.basicInfo, getSecondaryColorConfig);

export const getOfflineSecondaryColorSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getOfflineSecondaryColorConfig,
);

export const getLoginLogoSelector = createSelector((state: RootState) => state.basicInfo, getLoginLogo);

export const getResetPageTextSelector = createSelector((state: RootState) => state.basicInfo, getResetPageText);

export const getAppTitleSelector = createSelector((state: RootState) => state.basicInfo, getAppTitle);

export const getOfflineAppTitleHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getOfflineAppTitleHtml,
);

export const getOnlinePwaAppTitleHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getOnlinePwaAppTitleHtml,
);

export const getOfflineWelcomeMessageHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getOfflineWelcomeMessageHtml,
);

export const getFirstLoginTotpQrExplainerHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getFirstLoginTotpQrExplainerHtml,
);

export const getTotpCodeEntryExplainerHtmlSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getTotpCodeEntryExplainerHtml,
);

export const getLoginButtonSelector = createSelector((state: RootState) => state.basicInfo, getLoginHtml);

export const getCacheOnLoginSelector = createSelector((state: RootState) => state.basicInfo, getCacheOnLogin);

export const getHoverMenuSelector = createSelector((state: RootState) => state.basicInfo, getHoverMenu);

export const getRecaptchaSiteKeySelector = createSelector((state: RootState) => state.basicInfo, getRecaptchaSiteKey);

export const getShowContinueWithoutLoggingInSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getShowContinueWithoutLoggingIn,
);

export const getOverrideAppbarTitleForRouteSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getOverrideAppbarTitleForRoute,
);

export const getOverrideLoginAppbarTitleSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getOverrideLoginAppbarTitle,
);

export const getHideDashboardDropdownSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getHideDashboardDropdown,
);

export const getHideTaskScheduledDateSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getHideTaskScheduledDate,
);

const getTaskDrawerTaskLinkTemplateSelector = createSelector(
    (state: RootState) => state.basicInfo,
    getTaskDrawerTaskLinkTemplate,
);

export const getWillHaveSubmitButton = (state: RootState) =>
    fromNullable(state.viewConfig.views._TASK_LIST)
        .mapNullable((v) => v.searchFields)
        .mapNullable((sf) => Object.keys(sf).length > 0)
        .getOrElse(false);

export const getTaskDrawerTaskLinkUrlSelector = createSelector(
    getTaskDrawerTaskLinkTemplateSelector,
    (state: RootState) => state.viewConfig,
    getWillHaveSubmitButton,
    (template, viewConfig, taskPageWillHaveSubmitButton) => {
        if (template) {
            return evaluateFilterString(template, {
                currentUser: viewConfig.user && viewConfig.user.login,
                ...currentUserProperties(viewConfig),
                taskPageWillHaveSubmitButton,
            });
        }
        return null;
    },
);
